.card {
  min-height: fit-content;
  text-decoration: none;
  background-color: #f8f5ed;
  cursor: pointer;
  border-radius: 10px;
  filter: drop-shadow(0px 5.692px 5.692px rgba(0, 0, 0, 0.25));

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $gold;
    height: 8px;
    border-radius: 8px;
  }

  > img {
    width: 100%;
    height: 125px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }

  .tutorial-image {
    object-fit: cover;
  }

  .composer {
    object-fit: cover;
    object-position: 50% 40%;
  }

  .title {
    text-align: center;
    padding-top: 5px;
    font-weight: 600;
    height: 30px;
  }
}

.premium-card .card {
  background-color: #171717;
}

.premium-card .card .title {
  background-color: #171717;
  color: #e8ce80;
  font-weight: bold;
  border-radius: 10px;
}
