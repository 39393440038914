.pagination-buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: fit-content;

  .page-btn {
    opacity: 1;

    &:hover {
      opacity: 1;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .arrow-btn,
  .page-btn {
    font-size: 16px;
  }

  .ellipses:disabled {
    opacity: 1;
  }
}
