.meditation-set-container {
  overflow-x: hidden;
  height: fit-content;

  input {
    @include disableAutofill;
  }

  .instructions {
    text-align: center;
  }

  .mediation-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;

    form {
      .image-uploader,
      .delete-set,
      .delete-meditation {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .file-input-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: min(18px, max(0px, 1vw));

    .sound {
      background-color: transparent;
      border-color: black;
      color: black;
    }
  }

  .set-grouping {
    width: 100%;
    height: 2rem;
    border-radius: 5px;
    box-shadow: 0px 3px 10px -5px black;
    border: 1px solid black;
    color: grey;
    padding-left: 10px;
    font-size: 16px;
  }

  .description-field {
    height: 10rem;
  }

  .is-premium-wrapper {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f5ed;
    border: 0 solid transparent;
    border-radius: 5px;

    p {
      margin-left: 10px;
    }

    input {
      width: fit-content;
      min-width: 0px;
      margin-right: 20px;
      transform: scale(2);
    }
  }
}
