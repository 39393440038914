.card {
  text-align: center;
  overflow-wrap: break-word;
  word-break: normal;
  width: 350px;

  .banner {
    background-color: $gold;
    color: $black;
    padding: 5px;
    width: unset;
    font-size: 25px;

    .overlay {
      background: none;
    }
  }

  .rounded {
    border-radius: 0 0 10px 10px;
  }

  .oil-time-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 5px 20px;
    font-weight: 600;

    .duration {
      font-weight: 400;
    }
  }

  .more-info-container {
    padding-bottom: 30px;

    .additional-gifts {
      font-family: Arapey;
      font-size: 19px;
      padding: 8px;
    }

    .description-wrapper,
    .custom-gifts-wrapper {
      white-space: pre;
      font-size: 14px;
      font-weight: 300;
      line-height: 28px;
      text-wrap: wrap;
      padding: 10px 30px 0px 30px;
    }

    .description-wrapper {
      overflow-y: auto;
      min-height: 250px;
      max-height: 250px;
    }

    .custom-gifts-wrapper {
      font-weight: 500;
    }
  }
}

.nav-card {
}
