body {
  margin: 0px;
  font-family: "Open Sans", sans-serif;
  background-color: $background-color;
  color: $black;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

a {
  text-decoration: none;
  color: $black;
}

span {
  text-align: center;
}

h1 {
  background-color: $black;
  color: $gold;
  width: 100%;
  font-family: Arapey;
  font-style: italic;
  text-align: center;
  font-weight: lighter;
  padding: 10px;
  margin: 0;
}

.image-header {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #333;
  background-blend-mode: overlay;
}

h2 {
  text-align: center;
}

h3 {
  text-align: center;
  font-weight: 400;
}

p {
  font-weight: 300;
}

.red-text {
  color: #bd1b1b;
}

.org-filter,
.user-filter {
  height: 30px;
  width: 195px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;

  &:hover,
  &:focus {
    border: 1px solid rgba(0, 0, 0, 1);
  }
}

.button-and-search {
  @include flexbox(row-reverse, space-between);
}
