.meditation-content {
  width: 100%;
  position: relative;
  min-height: $desktop-height;
  overflow: hidden;

  .banner-wrapper {
    width: 100%;
  }

  .page-wrapper {
    margin: 1% 20%;
    min-width: 50%;

    form {
      width: 100%;

      .journal-content {
        width: 100%;
        max-width: 700px;
      }

      .button-wrapper {
        button[type="submit"] {
          margin: 10px 5px;
        }
      }
    }

    .gifts {
      white-space: pre-line;
      text-align: center;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
  }

  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    z-index: 1;
    gap: 15px;

    .card {
      .title {
        .banner {
          color: #171717;
          background-color: #e8ce80;
        }

        .more-info-container {
          .description-wrapper,
          .custom-gifts-wrapper {
            white-space: pre-wrap;
            padding: 0 5% 3% 5%;
          }

          .additional-gifts,
          .custom-gifts-wrapper {
            font-weight: 600;
          }

          .additional-gifts {
            margin: 2% 0;
          }
        }
      }
    }
  }

  .whisp-background-container {
    .top-left,
    .top-right {
      top: 56px;
    }
  }
}

.instructions-container {
  height: $desktop-height;

  .page-wrapper {
    .button-wrapper {
      margin: 8% 0;

      button {
        margin: 0;
      }
    }
  }
}
