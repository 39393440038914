.admin-btn-wrapper {
  text-align: center;
  padding: 20px 0px;

  button {
    width: 250px;
  }

  .add-set {
    background-color: white;
    border-color: black;
    color: black;
  }
}

.card-wrapper,
.editable {
  .admin-card {
    filter: drop-shadow(0px 5.692px 5.692px rgba(0, 0, 0, 0.25));

    .card {
      border-radius: 10px 10px 0px 0px;
      width: 350px;

      filter: none;
    }

    .price {
      font-style: italic;
      font-family: arapey;
      font-size: 18px;
      background-color: $gold;
      border-radius: 0px 0px 10px 10px;
      height: 35px;
      width: 350px;

      h3 {
        margin: 0px;
        padding: 5px;
      }
    }
  }

  .premium {
    .card {
      background-color: black;
      border-radius: 10px;

      img {
        height: 158px;
      }

      .title {
        height: 100%;
        background-color: black;
        color: $gold;
        border-radius: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
