@media (max-width: 1024px) {
  .carousel-container {
    .carousel-wrapper {
      .images-wrapper-large {
        img {
          width: 205px;
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .carousel-container {
    .control-buttons-wrapper {
      .save-cancel-wrapper {
        button {
          min-width: 120px;
        }
      }
    }
  }
}
