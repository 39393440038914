@media (max-width: 930px) {
  .journal-home-content {
    min-height: $media-height;
  }

  .meditation-home-content {
    .card-wrapper {
      .card {
        .tutorial-image,
        .logo,
        .piano {
          height: 20vh;
        }
      }
    }
  }
}
