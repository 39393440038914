.landing-container {
  position: relative;
  height: calc(100% - 30%);
  width: 100vw;

  .person-meditating-img {
    height: 95.5vh;
    min-height: 820px;
    width: 100vw;
    object-fit: cover;
  }

  .hero-wrapper {
    position: absolute;
    top: 2%;
    left: 3%;
    max-width: 50%;

    h2 {
      font-family: Arapey;
      font-style: italic;
      font-size: 80px;
      font-weight: 400;
      text-align: left;
      margin-bottom: 1%;
    }

    p {
      font-family: "Open Sans" sans-serif;
      font-size: 22px;
    }
  }

  .info-section-container {
    .logo-wrapper {
      display: flex;
      justify-content: center;

      img {
        height: 300px;
        width: 300px;
      }
    }

    .flavor-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      scroll-margin-top: 50px;

      .text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .resonance-app-icon {
        height: 200px;
        width: 200px;
        cursor: pointer;
      }

      .login-link {
        text-decoration: underline;
        margin: 0 4px;
      }
    }

    h2 {
      width: 90%;
      font-family: Arapey;
      font-style: italic;
      font-size: 28pt;
      font-weight: 100;
    }

    p {
      font-size: 16px;
      width: 90%;
      text-align: center;
      color: $black;
    }

    .meditation-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 80%;
        z-index: 1;

        .card {
          cursor: default;
        }
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .whisp-background-container {
    .top-left,
    .top-right {
      top: 95.5vh;
    }

    .bottom-left,
    .bottom-right {
      bottom: 6%;
    }
  }

  .normal-button {
    min-width: 80px;
    padding: 6px 10px;
    border: 2px solid $gold;
    border-radius: 20px;
    color: $gold;
    background-color: $black;
    font-size: large;
    margin: 3% 0;
    cursor: pointer;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
    text-wrap: nowrap;
    text-align: center;
  }
}
