.forgot-password-container {
  @include flexbox($align-items: flex-start);
  margin-top: 3%;

  .forgot-password-wrapper {
    @include flexbox(column, space-around);

    .logo {
      margin-bottom: 30px;
    }

    .recovery-wrapper {
      @include flexbox(column, space-around);
      color: $lightest;
      box-shadow: 0 2px 5px 2px rgb(0 0 0 / 20%);
      width: 300px;
      height: 250px;
      padding: 60px;

      hr {
        width: 100%;
      }

      button {
        text-transform: uppercase;
      }

      input {
        width: 280px;
        border-radius: 4px;
        padding: 8px 10px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        transition: 0.2s ease-in-out;

        &:hover,
        &:focus {
          border: 1px solid rgba(0, 0, 0, 1);
        }

        .email-input-container {
          input {
            width: 100%;
          }
        }
      }

      .recovery-btn {
        color: white;
        background-color: $second-lightest;
        height: 39px;
        width: 100%;
        margin-right: 0px;
        transition: 0.3s ease-in-out;
        border: 1px solid transparent;

        &:hover {
          background-color: $second-darkest;
        }

        &:disabled {
          background-color: $dark-grey;
          color: $light-grey;
        }
      }

      .recovery-form {
        @include flexbox(row, flex-start, flex-start);
        margin-top: 20px;
        width: 100%;

        .send-recovery {
          margin: 20px 0px 0px 0;
          width: 92.5%;
        }
      }
    }
  }
}
