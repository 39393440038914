.about-container {
  position: relative;
  height: calc(100vh - 60px);

  .banner {
    @include flexbox;
    height: 60px;
    width: 100%;
    background-color: $black;
    color: #e8ce80;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Arapey;
    font-size: 50px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.96px;
  }

  .card-wrapper {
    @include flexbox($align-items: flex-start);
    padding: 0;
    position: relative;
    z-index: 1;
    margin: 15px 0;
    gap: 15px;

    .card-link {
      text-decoration: none;

      .card {
        .title {
          color: $black;
          height: 30px;
        }
      }
    }
  }

  .whisp-background-container {
    .top-left,
    .top-right {
      top: 60px;
    }
  }
}
