.whisp-background-container {
  display: grid;
  background-color: #fff;
  width: 100%;

  .content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px;
  }

  > img {
    position: absolute;
    width: 15vw;

    &:last-child {
      z-index: -1;
    }
  }

  .top-left {
    top: 0;
  }

  .top-right {
    top: 0;
    right: 0;
  }

  .bottom-left {
    bottom: 0;
  }

  .bottom-right {
    bottom: 0;
    right: 0;
  }
}
