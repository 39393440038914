.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: $media-height;

  .fa-spinner {
    animation-timing-function: steps(8, end);
    animation-duration: 1s;
  }
}
