.my-account-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  overflow-x: hidden;

  .form-wrapper {
    @include flexbox(column);
    height: calc(100vh - 116px);
    width: 80%;

    .action-button-wrapper {
      @include flexbox(column);
    }
  }

  .whisp-background-container {
    .top-left,
    .top-right {
      top: 55px;
    }
  }

  .buttons-wrapper {
    display: flex;
    align-items: flex-end;
  }
}
