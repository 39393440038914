.gratitude-journal-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  min-height: $desktop-height;
  position: relative;

  .gratitude-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;

    .sort-add-journal-container {
      display: flex;
      justify-content: center;
      align-items: center;

      select {
        width: 300px;
        margin-right: 0.5rem;
        padding: 0.5rem 1rem;
        border: 2px solid black;
        font-size: 16px;
      }
    }

    .journal-entry-container {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }

    .hint {
      margin-top: 20px;
      text-align: center;
      padding: 0 30%;

      a {
        font-weight: 600;
        text-decoration: underline;
      }
    }

    .pagination-buttons-wrapper {
      margin: 2rem 0 3rem 0;
    }
  }

  .whisp-background-container {
    z-index: -1;
    .top-left,
    .top-right {
      top: 56px;
    }
  }
}

.gratitude-journal-form-container {
  width: 100%;

  .text-area-container {
    width: 155%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    position: relative;
    z-index: 1;
  }
}
