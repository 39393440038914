.journal-card-wrapper {
  background-color: white;
  border: 1px solid #b8b2a3;
  width: 550px;
  height: 300px;
  margin: 1rem;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);

  .journal-header {
    background-color: #b8b2a3;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0.25rem 1rem;
    font-size: 20px;
    font-weight: 600;

    .header-content {
      width: 95%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .edit-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 55px;
      }
    }
  }

  .journal-content {
    width: 90%;
    margin: auto;
    height: 250px;

    .journal-entry-wrapper {
      height: 250px;
      overflow-y: auto;

      .entry {
        word-wrap: break-word;
        margin: 1rem 0;
        font-size: 18px;
        white-space: pre-wrap;

        span {
          color: #b8b2a3;
          font-weight: 600;
          margin-right: 0.5rem;
        }
      }
    }

    .meditation-title {
      border-bottom: 2px solid #b8b2a3;
      padding: 0.5rem 0;
      text-align: center;
      font-family: arapey;
      font-style: italic;
      font-size: 24px;
    }

    .meditation-content-wrapper {
      height: 82%;
      overflow-y: auto;

      .meditation-entry-title {
        font-weight: 600;
        font-size: 18px;
        margin: 1rem 0;
      }

      .meditation-journal-content {
        font-size: 18px;
        font-weight: 300;
        word-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }
}
