@media (max-width: 900px) {
  form {
    width: 80%;
  }

  .dark {
    img {
      visibility: visible;
    }
  }

  .register-form {
    width: 100%;
  }
}

@media (max-width: 430px) {
  .meditation-set-container {
    .instructions {
      width: 96%;
    }

    .mediation-form-container {
      width: 90%;

      form {
        width: 100%;
      }
    }
  }
}
