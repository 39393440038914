@media (max-width: 930px) {
  .landing-container {
    .person-meditating-img {
      opacity: 0.6;
    }

    .hero-wrapper {
      left: 6%;
    }

    .footer-content {
      .footer-background {
        clip-path: ellipse(100% 70% at 50% 70%);
        height: 275px;
      }

      .social-media {
        grid-template-columns: unset;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 15%;
      }
    }
  }
}

@media (max-height: 860px) {
  .landing-container {
    .whisp-background-container {
      .top-left,
      .top-right {
        top: 821px;
      }
    }
  }
}

@media (max-width: 820px) {
  .landing-container {
    .info-section-container {
      .logo-wrapper {
        display: flex;
        justify-content: center;

        img {
          height: 50%;
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: 530px) {
  .landing-container {
    .person-meditating-img {
      object-position: 57.5%;
      min-height: unset;
    }

    .hero-wrapper {
      max-width: unset;
      left: unset;
      padding: 6%;
      text-align: center;

      h2 {
        font-size: 40px;
        text-align: center;
      }

      p {
        font-size: 16px;
      }
    }

    .info-section-container {
      .meditation-section {
        .cards {
          width: unset;
        }
      }

      h2 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
      }

      .normal-button {
        margin: 6% 0;
      }
    }

    .whisp-background-container {
      .top-left,
      .top-right {
        visibility: visible;
        top: 95.5vh;
      }

      .bottom-left,
      .bottom-right {
        visibility: visible;
        bottom: 260px;
      }
    }
  }
}

@media (max-width: 375px) {
  .landing-container {
    .hero-wrapper {
      top: -0.5%;
    }
  }
}
