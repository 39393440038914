.subscription-container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  min-height: $desktop-height;

  .price-wrapper {
    height: fit-content;
    background-color: $gold;
    h2 {
      font-weight: normal;
    }
  }

  h2 {
    z-index: 1;
    padding: 0 10px;
  }

  .meditations {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 1;
    margin-bottom: 30px;
    row-gap: 46px;
    column-gap: 15px;
    padding: 0px 15px;

    .meditation-set {
      filter: drop-shadow(0px 5.692px 5.692px rgba(0, 0, 0, 0.25));

      .price {
        font-style: italic;
        font-family: arapey;
        font-size: 18px;
        background-color: $gold;
        border-radius: 0px 0px 10px 10px;
        height: 40px;

        h3 {
          margin: 0px;
          padding: 8px;
        }
      }
    }

    .meditation-card {
      border-radius: 10px 10px 0 0;
      filter: unset;
    }
  }

  .modal-image {
    width: 200px;
    max-height: 200px;
  }

  .whisp-background-container {
    .top-left,
    .top-right {
      top: 128px;
    }
  }
}
