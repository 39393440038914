@media (max-width: 930px) {
  .my-account-wrapper {
    min-height: $media-height;
    overflow-x: hidden;
    .form-wrapper {
      height: 100%;
    }
  }
}

@media (max-width: 500px) {
  .my-account-wrapper {
    .form-wrapper {
      form {
        width: auto;
      }
    }
  }
}

@media (max-height: 590px) {
  .my-account-wrapper {
    .form-wrapper {
      button {
        margin: 6px;
      }
    }
  }
}
