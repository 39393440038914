.contact-container {
  position: relative;
  height: calc(100% - 30%);
  width: 100vw;

  h1 {
    padding: 10px 0;
  }

  .contact-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    p {
      width: 700px;
      text-align: center;
    }

    .input-wrapper {
      width: 700px;

      label {
        margin: 0 auto;
        color: #171717;
        padding: 0.25rem 0;
        margin-top: 0.5rem;
        text-align: left;
      }

      input {
        width: calc(100% - 34px);
        height: 15px;
        padding: 0.5rem 15px;
        border: 1px solid #171717;
        background-color: white;
        border-radius: 5px;
        font-size: 16px;
        outline: none;
        min-width: 260px;
        margin-bottom: 12px;
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
        }
      }

      .message-input {
        width: calc(100% - 34px);
        height: 251px;
        padding: 0.5rem 15px;
        border: 1px solid #171717;
        color: $gold;
        background-color: white;
        border-radius: 5px;
        font-size: 16px;
        outline: none;
        min-width: 260px;
        margin-bottom: 12px;
      }

      .button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          width: 160px;
          &:disabled {
            opacity: 0.5;
            cursor: default;
          }
        }
      }
    }
  }
  .whisp-background-container {
    .top-left,
    .top-right {
      top: 56px;
    }

    .bottom-left,
    .bottom-right {
      bottom: 18%;
    }
  }
}
