@media screen and (max-width: 850px) {
  .contact-container {
    width: 100%;
    .contact-wrapper {
      padding: 11% 0px 7% 0;

      p {
        width: 538px;
      }

      .input-wrapper {
        width: 538px;
      }
    }

    .whisp-background-container {
      .top-left,
      .top-right {
        top: 56px;
      }

      .bottom-left,
      .bottom-right {
        bottom: 16%;
      }
    }

    .social-media {
      grid-template-columns: 1fr 2.5fr 1fr;
    }
  }
}

@media screen and (max-width: 540px) {
  .navbar-container {
    width: 100%;
  }

  .contact-container {
    width: 100%;
    .contact-wrapper {
      p {
        width: 399px;
      }

      .input-wrapper {
        width: 408px;
      }
    }
  }

  .footer-content {
    .social-media {
      width: 285px;
      grid-template-columns: 1fr 2fr 1fr;
    }
  }
}

@media screen and (max-width: 425px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .contact-wrapper {
      h2 {
        width: 300px;
      }

      p {
        width: 325px;
      }

      .input-wrapper {
        width: 325px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .contact-container {
    .contact-wrapper {
      p {
        width: 325px;
      }

      .input-wrapper {
        width: 325px;
      }
    }
  }
}
