@media (max-width: 930px) {
  .meditation-home-content {
    height: $media-height;
  }

  .meditation-wrapper {
    min-height: $media-height;

    .backdrop {
      .instructions-container {
        height: calc(100vh - 255px);
      }
    }
  }
}

@media (max-width: 750px) {
  .meditation-home-content {
    height: auto;
  }
}
