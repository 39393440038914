@mixin flexbox($flex-dir: row, $justify-cont: center, $align-items: center) {
  display: flex;
  flex-direction: $flex-dir;
  justify-content: $justify-cont;
  align-items: $align-items;
}

@mixin disableAutofill {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 0px transparent inset !important;
    -webkit-text-fill-color: black !important;
  }
}
