.premium-banner {
  position: relative;
  top: 130px;
  right: 25px;
  z-index: 10;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 0px;
  img {
    height: 130px;
  }
}
