.login-container {
  @include flexbox(column);
  width: 100%;
  height: 100vh;
  background-color: #171717;

  p {
    color: $gold;
    font-weight: 400;
    text-align: center;
    padding: 0 12%;
  }

  .logo {
    width: 210px;
  }

  .whisp-background-container {
    img {
      width: 10em;
    }
    .content-wrapper {
      justify-self: center;
    }
  }

  .login-form {
    @include flexbox(column);

    button[type="submit"] {
      margin-top: 13%;
    }

    .error,
    .error-message {
      color: $dark-gold;
    }
  }

  > input {
    width: 23vw;
  }

  .error-message {
    height: 0;
  }

  .error {
    margin-top: 5px;
    color: $gold;
  }

  .create-account-link {
    text-decoration: none;
    font-weight: 200;
    font-size: 16px;
    padding: 4px 0;
    color: $gold;
  }
}
