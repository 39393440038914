.image-cropper-container {
  @include flexbox(column, flex-start);

  .image-preview-wrapper {
    @include flexbox(column, flex-start, flex-start);
    margin: 24px 32px;

    .image-preview {
      width: 256px;
      height: 256px;
      border-radius: 50%;
      cursor: grab;
      background-repeat: no-repeat;
    }
  }

  .slide-container {
    width: 100%;
    @include flexbox(column, flex-start);
  }

  .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 50%;
    height: 10px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    border-radius: 5px;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    position: unset;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: $lightest;
    border-radius: 50%;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: $lightest;
    cursor: pointer;
  }

  .button-wrapper {
    display: flex;

    button {
      padding: 2%;
      margin: 5%;
      height: 35px;
      width: 150px;

      &:hover {
        opacity: 0.5;
      }
    }

    .save {
      background-color: $lightest;
      color: white;
    }
  }
}
