label {
  width: 100%;
  margin: 0 auto;
  color: $gold;
  padding: 0.25rem 0;
  margin-top: 0.5rem;
  text-align: left;
}

.label-alternate {
  color: $black;
}

textarea {
  font-family: "Open Sans";
  width: calc(100% - 30px);
  border-radius: 5px;
  padding: 5px 15px;
  border: 1px solid $black;
  resize: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  min-width: 260px;
  font-size: 16px;
}

.text-area-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px 0px;
}

input {
  width: calc(100% - 34px);
  height: 15px;
  padding: 0.5rem 15px;
  border: 1px solid $gold;
  border-radius: 5px;
  background-color: #232323;
  color: $gold;
  font-size: 16px;
  outline: none;
  min-width: 260px;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #232323 inset !important;
    -webkit-text-fill-color: $gold;
  }
}

.input-alternate {
  color: $black;
  border-color: $black;
  background-color: $background-color;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    -webkit-text-fill-color: $black;
  }
}

.text-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span.error {
    margin-top: 4px;
  }
}

.password-field {
  width: 100%;
  display: grid;

  button {
    position: absolute;
    justify-self: right;
    align-self: center;
    margin: 0;
    padding: 0;
    margin-right: 15px;
    border: none;
    width: 20px;
    min-width: unset;
    background-color: transparent;
  }

  svg {
    font-size: 1em;
    width: 1.5em;
  }
}
