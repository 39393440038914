.bell-icon,
.lock-icon {
  position: relative;
  top: 110px;
  z-index: 10;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 0px;
}

.bell-icon {
  cursor: pointer;
}
