.meditation-wrapper {
  background-position: center;
  background-size: cover;
  min-height: $desktop-height;

  .backdrop {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    min-height: inherit;

    .instructions-container {
      font-size: 21px;
      opacity: 1;
      height: calc(100vh - 175px);
      padding: 0 10%;
      overflow-y: auto;
      transition: opacity ease-out 2s;
    }

    .centered-button {
      display: flex;
      justify-content: center;
    }

    .hidden {
      opacity: 0;
    }
  }

  .hidden {
    background-color: rgb(0, 0, 0, 0);
    backdrop-filter: none;
    transition: all 2s ease-in-out;
  }

  .banner-continue {
    @include flexbox();
    width: 100%;
    height: 45px;
    font-family: Arapey;
    font-style: italic;
    text-align: center;
    font-weight: lighter;
    justify-content: center;
    background-color: rgba(248, 245, 237, 0.9);
    padding: 0;
    margin: 0;
    display: flex;
    color: $black;
    font-family: unset;
    font-style: unset;
    font-size: large;

    svg {
      padding: 0 10px;
    }
  }
}

.audio {
  display: none;
}

.bell-icon {
  cursor: pointer;
}
