@media (max-width: 430px) {
  .whisp-background-container {
    .top-left,
    .top-right,
    .bottom-left,
    .bottom-right {
      visibility: hidden;
    }
  }
}
