.hamburger {
  @include flexbox($align-items: flex-end);
  width: 30px;
  height: 30px;

  svg {
    path {
      transition: fill 0.5s ease-in-out;
    }
  }

  &:hover {
    svg {
      path {
        fill: rgb(23, 23, 23, 0.6);
      }
    }
  }
}
