@media (max-width: 930px) {
  .meditation-content {
    min-height: $media-height;
  }

  .instructions-container {
    height: auto;

    .page-wrapper {
      margin: 0 20%;
    }
  }
}

@media (max-width: 750px) {
  .meditation-content {
    min-width: unset;
  }
}

@media (max-width: 430px) {
  .meditation-content,
  .meditation-home-content {
    .page-wrapper {
      margin: 5%;
    }
  }
}
