.narrow-paper {
  width: 550px;
}

.get-wrapper {
  .get-detail-wrapper {
    .back-button {
      @include flexbox;
      margin-bottom: 30px;
    }

    .detail-wrapper {
      margin: 0px;

      .form-wrapper {
        .details {
          .top-section {
            @include flexbox($align-items: flex-start);

            h1 {
              width: 375px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .switch-wrapper {
              @include flexbox($justify-cont: flex-start);
            }
          }

          .middle-section {
            @include flexbox(
              $align-items: flex-start,
              $justify-cont: space-between
            );

            .icon-and-details {
              @include flexbox($justify-cont: flex-start);
              height: 130px;

              .avatar-container {
                width: 8rem;
                height: 8rem;
                margin-right: 1rem;
                font-size: 4em;
              }

              i {
                color: $second-lightest;
                font-size: 80px;
                margin-right: 30px;
              }

              .role {
                text-transform: capitalize;
              }
            }
          }
          .flex-row {
            justify-content: flex-end;
            align-items: flex-end;
            height: 75px;

            button {
              margin-left: 10px;
              margin-right: 0px;
              height: 35px;
            }
          }
        }
      }
    }

    .user-list {
      .list-page {
        margin: 0px;
      }
    }
  }
}
