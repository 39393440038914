// foundation
$darkest: black;
$second-darkest: #3e5168;
$second-lightest: #6c8cb5;
$lightest: #8ab3e8;
$light-grey: #cccccc;
$dark-grey: #aaaaaa;
$rose: #a2666f;
$coral: #f49390;
$light-blue: #3e51687d;
$light-white: #fafafa;

$lava: #c42021;
$darker-lava: #991b1b;
$green: #6da34d;
$orange: #ee9433;

$switchHeight: 30px;
$switchWidth: 100px;
$sliderHeight: $switchHeight - 8;
$sliderWidth: calc(($switchWidth - 8px) / 2);
$desktop-height: calc(100vh - 60px);
$media-height: calc(100vh - 140px);

//resonance
$background-color: #fff;
$dark-brown: #594e3c;
$black: #171717;
$gold: #e8ce80;
$dark-gold: #82734b;
