.registration-container {
  @include flexbox(column);
  width: 100%;
  height: 100vh;
  background-color: #171717;

  img {
    width: 10em;
  }

  .registration-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;

    .link-wrapper {
      text-align: right;
      margin-top: 0.5rem;
      width: 100%;

      a {
        text-decoration: none;
        color: $dark-gold;
      }
    }

    button[type="submit"] {
      margin-top: 1.5em;
    }
  }

  .logo {
    display: flex;
    justify-content: center;
  }

  .error {
    margin-top: 5px;
    color: $gold;
  }
}
