.gratitude-journal-content {
  width: 100%;
  .banner-wrapper {
    width: 100%;
  }

  .journal-wrapper {
    margin: 5% 20%;

    form {
      width: 100%;
    }
  }
}
