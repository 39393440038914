@media (max-width: 930px) {
  .about-container {
    .banner {
      height: 48px;
      font-size: 40px;
    }

    .card-wrapper {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .whisp-background-container {
      .top-left,
      .top-right {
        top: 48px;
      }
    }
  }
}

@media (max-width: 430px) {
  .about-container {
    .banner {
      height: 40px;
      font-size: 30px;
    }
  }
}
