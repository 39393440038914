.nav-item {
  font-size: large;
  padding: 0px 22px;
  font-size: 20px;
  color: rgb(23, 23, 23, 0.6);
  letter-spacing: 0.3px;
  margin-top: 2rem;
  transition: all 0.5s ease-in-out;
  text-align: center;
  width: 100%;
  white-space: nowrap;

  a {
    text-decoration: none;
    font-size: large;
    padding: 0px;
    font-size: 20px;
    color: rgb(23, 23, 23, 0.6);
    letter-spacing: 0.3px;
    margin-top: 2rem;
    transition: all 0.5s ease-in-out;
  }

  svg {
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #171717;
    a {
      color: #171717;
    }
  }
}

.more {
  @include flexbox;
}

.bold {
  color: #171717;
  font-weight: bold;

  a {
    color: #171717;
    font-weight: bold;
  }
}
