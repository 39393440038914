@media (max-width: 750px) {
  .login-container {
    .whisp-background-container {
      > img {
        width: 15vw;
      }

      .top-left,
      .top-right,
      .bottom-left,
      .bottom-right {
        visibility: visible;
      }

      .content-wrapper {
        img {
          min-width: 200px;
        }
      }
    }
  }

  .body-container {
    .whisp-background-container {
      > img {
        width: 15vw;
      }
    }
  }
}

@media (max-width: 320px) {
  input {
    font-size: 14px;
    min-width: 225px;
  }
}
