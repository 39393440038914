.subscription-modal-container {
  @include flexbox($flex-dir: column);
  justify-content: flex-start;
  font-family: "Open Sans";
  width: 100%;
  height: 100%;
  border-radius: 10px;

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $gold;
    height: 8px;
    border-radius: 8px;
  }

  .modal-image {
    height: 30vh;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
  }

  .meditation-set-name {
    h3 {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .price {
    width: 100%;
    padding: 15px 0px;
    background-color: #e8ce80;
    text-align: center;
    font-size: 15px;
  }

  button {
    width: 300px;
    height: 35px;
    font-size: 16px;
    font-weight: 900;
    margin: 17px 0 17px 0;
  }

  .cancel-sub-info {
    text-align: center;
    padding: 0px 20px;

    h2 {
      font-size: 22px;
    }
  }

  .button-wrapper {
    width: 85%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5% 0;
    gap: 20px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 35px;
      font-size: 16px;
      font-weight: bold;
      border: 2px solid $black;
      border-radius: 20px;
      color: $black;
      background-color: transparent;
      cursor: pointer;
      text-wrap: nowrap;
      box-sizing: border-box;
    }

    button {
      width: 50%;
    }
  }

  .included-meditations-wrapper {
    width: 100%;
    max-height: 28vh;
    overflow-y: auto;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
}
