@media (min-width: 1500px) {
  .about-section-container {
    .banner-wrapper {
      img {
        object-position: 50% 38%;
      }
    }
  }
}

@media (max-width: 930px) {
  .about-section-container {
    section {
      padding: 0 5%;

      a {
        font-weight: 600;
      }
    }

    .whisp-background-wrapper {
      img {
        visibility: hidden;
      }
    }

    .banner-wrapper {
      .banner-text {
        height: 50px;
        font-size: 32px;
      }
    }
  }
}

@media (max-width: 520px) {
  .about-section-container {
    section {
      padding: 0 5%;
      font-size: 16px;

      p {
        margin-top: 7px;
        margin-bottom: 8px;
      }
    }

    .banner-wrapper {
      img {
        height: 180px;
        object-position: 50% 30%;
      }

      .banner-text {
        font-size: 24px;
      }
    }
  }
}
