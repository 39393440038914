.meditation-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  p {
    padding: 5px 0;
    margin: 5px 0 0;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
  }

  input {
    height: 5%;
    width: 80%;
    border-radius: 10px;
    margin: 5px 0 5px 0;
    background-color: white;
    border: 1px solid $black;
    text-align: center;
    color: $black;
    min-width: unset;

    &:focus {
      border: 2px solid $black;
    }
  }

  .image-display {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 30px;
    border: dotted 2px black;
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;

    .inner-image-display {
      display: flex;
      justify-content: center;
      margin: 10px 0;

      .chosen-img {
        width: 35%;
        border-radius: 10px;
      }

      .close {
        height: 25px;
        width: 25px;
        text-align: center;
        position: absolute;
        right: 2%;
        top: 5%;
      }
    }
  }
  .meditation-options-container {
    border: 1px solid black;
    border-radius: 10px;
    width: 80%;
    height: 480px;
    overflow-y: auto;

    .meditation-option {
      margin: 0.25rem auto;
      text-align: center;
      width: 80%;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background-color: #e8ce80;
      }
    }

    .selected {
      background-color: #e8ce80;
    }
  }

  .button-wrapper {
    margin-top: 15px;
    height: 10%;
    width: 80%;
    display: flex;
    justify-content: space-around;
  }
}
