@media screen and (max-width: 768px) {
  .journal-card-wrapper {
    width: 300px;
    height: 200px;

    .journal-header {
      font-size: 14px;
      width: 270px;
    }
    .journal-content {
      height: fit-content;
      max-height: 160px;

      .journal-entry-wrapper {
        height: 160px;

        .entry {
          font-size: 14px;
        }
      }
    }

    .meditation-title {
      padding: 0.25rem 0;
      font-size: 18px;
      margin: 0;
    }

    .meditation-content-wrapper {
      max-height: 120px;

      .meditation-entry-title {
        font-size: 16px;
        margin: 0 0 0.25rem 0;
      }

      .meditation-journal-content {
        font-size: 14px;
      }
    }
  }
}
