@media (max-width: 930px) {
  .gratitude-journal-page {
    min-height: $media-height;
  }
}

@media screen and (min-width: 768px) and (max-width: 994px) {
  .meditation-content {
    margin: unset;

    .text-area-container {
      width: 80%;
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 768px) {
  .gratitude-journal-page {
    .gratitude-content-wrapper {
      .hint {
        padding: 0 5%;
      }
    }
  }

  .meditation-content {
    margin: unset;

    .text-area-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20%;

      textarea {
        height: 150px;
      }
    }
  }

  .meditation-form-container {
    .button-wrapper {
      button {
        min-width: 130px;
      }
    }
  }

  .sort-add-journal-container {
    flex-direction: column;
    margin-top: 1.2rem;
  }
}

@media screen and (max-width: 768px) and (max-height: 800px) {
  .meditation-form-container {
    height: 550px;
  }
}

@media screen and (max-width: 767px) {
  .meditation-form-container {
    .meditation-options-container {
      height: 255px;
    }
  }
}

@media screen and (min-width: 768px) {
  .meditation-form-container {
    .meditation-options-container {
      height: 345px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .meditation-form-container {
    .meditation-options-container {
      height: 345px;
    }
  }
}

@media screen and (min-width: 1440px) {
  .meditation-form-container {
    .meditation-options-container {
      height: 485px;
    }
  }
}

@media (max-width: 360px) {
  .meditation-content {
    .page-wrapper {
      .gratitude-journal-form-container {
        .button-wrapper {
          flex-direction: column-reverse;
        }
      }
    }
  }
}
