.x-icon {
  @include flexbox($align-items: flex-end);
  width: 30px;
  height: 30px;

  img {
    width: 1.2em;
    transition: opacity 0.5s ease-in-out;

    &:hover {
      opacity: 0.5;
    }
  }
}
