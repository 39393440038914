.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 10px;
  margin-top: 0.5%;

  .carousel-wrapper {
    .images-wrapper {
      img {
        display: none;
        object-fit: cover;
      }

      .selectable {
        cursor: pointer;
      }

      .selected {
        outline: 3px solid #e8ce80;
        border-radius: 5px;
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
      }

      .show {
        display: inline-block;
      }
    }

    .images-wrapper-small {
      width: 97%;
      min-width: fit-content;
      max-width: 580px;
      height: min(498px, max(331px, 103vw));
      gap: min(18px, max(12px, 3vw));
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      img {
        width: min(218.4px, max(144px, 42.68vw));
        height: min(240px, max(158px, 50vw));
      }
    }

    .images-wrapper-medium {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      justify-content: center;
      justify-items: center;
      width: 100%;
      gap: 15px;
      margin-bottom: 10px;

      img {
        width: 120px;
        height: 120px;
      }
    }

    .images-wrapper-large {
      min-height: 378px;
      column-gap: 10px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
      justify-content: center;

      img {
        border-radius: 5px;
        width: 230px;
        height: 85px;
      }
    }

    .images-wrapper-medium,
    .images-wrapper-small {
      margin-top: 10px;
      align-items: center;

      img {
        border-radius: 10px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
    }

    .loading-wrapper {
      display: flex;
      align-items: center;
      column-gap: 0px;

      .fa-spinner {
        animation-timing-function: steps(8, end);
        animation-duration: 1s;
      }
    }
  }

  .control-buttons-wrapper {
    @include flexbox(column);
  }
}
