form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 38rem;

  .link-wrapper {
    text-align: right;
    margin-top: 0.5rem;
    width: 100%;

    a {
      text-decoration: none;
      color: $dark-gold;
    }
  }

  button[type="submit"] {
    margin-top: 13%;
  }
}

.confirm-form {
  width: 100%;
  padding-top: 20px;
  text-align: center;

  p {
    margin: 0px 50px;
  }

  span {
    color: red;
  }
}

.register-form {
  width: 20rem;
}
