.meditation-set-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: $desktop-height;
  overflow: hidden;

  .sound-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px 100px;

    button {
      width: 250px;
    }

    .order-meditations {
      background-color: white;
      color: black;
      border-color: black;
    }

    input {
      text-align: center;
      border-color: black;
      background-color: transparent;
      padding-right: 10px;
      color: black;
    }
  }

  .meditations {
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 5%;
    }
    .title {
      display: flex;
      flex-direction: column;
      height: 65px;
      width: 100%;
    }

    .name {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      gap: 150px;
      align-items: center;
      height: 65px;
      font-family: "Open-sans";
      font-weight: bold;
      .duration {
        font-weight: lighter;
        opacity: 0.6;
      }
    }

    .banner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      width: calc(100% - 10px);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      font-family: Arapey;
      font-style: Italic;
    }
  }

  .premium {
    .card {
      cursor: auto;
    }
  }

  .whisp-background-container {
    .top-left,
    .top-right {
      top: 56px;
      z-index: -1;
    }
  }
}
