.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer-background {
    position: absolute;
    background-color: $black;
    height: 225px;
    width: 100%;
    bottom: 0;
    clip-path: ellipse(100% 100% at 50% 100%);
  }

  .contact-us-text {
    font-family: Arapey;
    font-style: italic;
    font-size: 20pt;
    margin-top: 15px;
    color: $gold;
    z-index: 1;
  }

  .line {
    border: 1px solid $gold;
    margin: 10px auto;
    width: 35%;
    z-index: 1;
  }

  .social-media {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    color: $gold;
    margin-top: 15px;
    width: 35%;
    z-index: 1;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gold;

      a {
        margin-left: 4px;
        color: $gold;
      }
    }
  }

  .copyright {
    margin: 50px 0 10px;
    display: flex;
    justify-content: center;
    color: $gold;
    font-family: sans-serif;
    font-size: 8pt;
    z-index: 1;
  }
}
