.journal-home-content {
  width: 100%;
  position: relative;
  min-height: $desktop-height;
  overflow: hidden;

  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    z-index: 1;
    margin: 15px 0;
    gap: 15px;
  }

  .whisp-background-container {
    .top-left,
    .top-right {
      top: 56px;
    }
  }
}
