button {
  min-width: 150px;
  height: 36px;
  line-height: 0;
  border: 2px solid $gold;
  border-radius: 20px;
  color: $gold;
  background-color: $black;
  font-size: large;
  margin: 10px 5px;
  cursor: pointer;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  text-wrap: nowrap;

  &:disabled {
    cursor: default;
  }
}

.button-alternate {
  background-color: transparent;
  border-color: $black;
  color: $black;
  filter: none;
}

.button-text,
.button-text-alt,
.button-text-alt-number,
.button-icon {
  border: none;
  background-color: #00000000;
  color: $black;

  &:hover {
    text-decoration: underline;
  }
}

.button-icon {
  padding: 0;
  margin: 0;
  min-width: unset;
  height: unset;
}

.button-text-alt,
.button-text-alt-number {
  height: 20px;
  width: 20px;
  min-width: 0;
  padding: 0;
  font-weight: 700;

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.button-text-alt-number {
  opacity: 0.5;

  &:disabled {
    opacity: 1;
  }
}

.circle-button {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 10px;
  padding: 10px;
  min-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
}

.play-button {
  svg {
    position: relative;
    transform: translate(2px, 0);
  }
}
