@media (max-width: 1024px) {
  .order-meditations-form-container {
    height: 100%;

    .meditations-text {
      padding: 0 10px;
    }

    .order-meditations-wrapper {
      width: 90%;
      max-width: 600px;
      height: 90%;

      .meditation-item {
        grid-template-columns: 50% 40% 10%;
        font-size: 16px;
        height: 36px;
      }
    }
  }
}

@media (max-width: 425px) {
  .order-meditations-form-container {
    .buttons-wrapper {
      button {
        min-width: 120px;
        height: 35px;
      }
    }
  }
}
