.confirmation-container {
  @include flexbox;
  flex-direction: column;
  height: 100vh;

  .confirmation-wrapper {
    @include flexbox;
    flex-direction: column;
  }
}
