.about-section-container {
  position: relative;

  .banner-wrapper {
    position: relative;

    img {
      width: 100%;
      height: 404px;
      object-fit: cover;
      object-position: 50% 45%;
    }

    .banner-text {
      @include flexbox();
      width: 100%;
      color: #e8ce80;
      background-color: rgba(0, 0, 0, 0.6);
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: Arapey;
      font-size: 50px;
      font-style: italic;
      font-weight: 400;
      letter-spacing: 0.96px;
      text-align: center;
      padding: 7px 0px;
      position: absolute;
      bottom: 5px;
    }
  }

  section {
    @include flexbox(column);
    padding: 0 20%;
    color: $black;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.36px;

    a {
      color: $black;
      font-weight: 600;
    }

    .facebook {
      margin-left: 5px;
      margin-right: 5px;
    }

    .instagram {
      margin-left: 5px;
    }
  }

  .consultation-question {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;

    p {
      font-weight: 600;
      font-size: 20px;
      text-align: center;
    }

    .consultation-button {
      width: 160px;
      border: 2px solid $gold;
      border-radius: 24px;
      color: $gold;
      background-color: #171717;
      font-size: large;
      margin: 10px 5px;
      cursor: pointer;
      filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
      text-wrap: nowrap;
      text-align: center;
      padding: 11px;
    }
  }

  .whisp-background-wrapper {
    .whisp-mid-left,
    .whisp-mid-right {
      position: absolute;
      top: 50%;
    }

    .whisp-mid-right {
      right: 0;
    }

    .whisp-background-container {
      .top-left,
      .top-right {
        top: 404px;
      }
    }
  }
}
