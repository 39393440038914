.notification-wrapper {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 300px;
}

.notification-item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  animation: SlideLeft 0.2s;
  animation-fill-mode: forwards;
  width: 300px;
}

@keyframes SlideLeft {
  0% {
    margin-left: 120%;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes SlideRight {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 120%;
  }
}

.notification-item.exit {
  animation: SlideRight 0.2s;
  animation-fill-mode: forwards;
}

.notification-item p {
  margin: 0;
  padding: 10px;
  overflow: hidden;
}

.notification-item .bar {
  height: 10px;
}

.Toastify {
  button {
    min-width: 100px;
  }

  .Toastify__toast-container {
    .Toastify__toast--success {
      background-color: #171717;
      border: 2px solid #e8ce80;
      border-radius: 10px;
      color: #e8ce80;

      .fas {
        padding-right: 10px;
      }
    }

    .Toastify__toast--error {
      background-color: #171717;
      border: 2px solid #e8ce80;
      border-radius: 10px;
      color: #e8ce80;

      .fas {
        padding-right: 10px;
      }
    }

    .Toastify__toast--warning {
      background-color: #fdd656;

      .fas {
        padding-right: 10px;
      }
    }
  }
}
