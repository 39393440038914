.meditation-home-content {
  width: 100%;
  position: relative;
  height: $desktop-height;
  overflow: hidden;

  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    z-index: 1;
    margin: 15px 0;
    gap: 15px;

    .card {
      .tutorial-image,
      .logo,
      .piano {
        height: 30vh;
      }
    }
  }

  .whisp-background-container {
    .top-left,
    .top-right {
      top: 56px;
    }
  }
}
