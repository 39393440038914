@media (min-width: 930px) {
  .navbar-container {
    justify-content: space-between;

    .logo-wrapper {
      margin-left: 70px;
    }

    .dropdown-icon {
      display: none;
    }

    .nav-menu {
      display: none;
    }

    .navbar-links {
      display: flex;
      align-items: center;
    }
    .spacer {
      display: none;
    }
  }

  .footer-container {
    display: none;
  }

  .nav-item {
    margin: 0;
    a {
      margin: 0;
    }
  }
}

@media (max-height: 725px) {
  .navbar-container {
    .nav-menu {
      .link-wrapper {
        padding: 1.4vh 0;
      }
    }
  }
}

@media (max-height: 640px) {
  .navbar-container {
    .nav-menu {
      .link-wrapper {
        padding: 1.3vh 0;
      }
    }
  }
}

@media (max-width: 930px) {
  .nav-item:last-child {
    margin-bottom: 30px;
  }
}
