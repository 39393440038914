.drag-drop-form-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 9fr;
  border: 1px dashed black;
  border-radius: 15px;
  justify-items: center;

  .form-header-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px dashed black;

    .form-header {
      font-size: 19px;
      font-weight: 700;
    }

    .fa-xmark {
      font-size: 21px;
      position: absolute;
      right: 5%;
      cursor: pointer;
    }
  }
}
