.navbar-container {
  @include flexbox();
  column-gap: 15px;
  background-color: white;
  height: 60px;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  position: sticky;
  z-index: 2;
  top: 0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);

  .logo-wrapper {
    height: 75%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .dropdown-icon {
    transition: transform 1.3s ease;

    svg,
    img {
      height: 1.7em;
      margin-top: 7px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .rotated {
    transform: rotate(90deg);
  }

  .nav-menu {
    @include flexbox(column, flex-start);
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 0;
    transition: all 1.3s ease-in-out;
    overflow-y: auto;
    z-index: 1;
    height: $desktop-height;
    width: 100%;

    .mobile-line-break {
      width: 80%;
      border: 1.5px solid #e8ce80;
      border-radius: 5px;
      margin-top: 2rem;
    }
  }

  .active {
    background: #f8f5ed;
    left: 0;
    opacity: 1;
    overflow-x: hidden;
  }

  .navbar-links {
    display: none;
    margin-right: 70px;
  }
}
