.footer-container {
  position: sticky;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: #171717;

  .footer-wrapper {
    @include flexbox();
    width: 100%;
    height: 100%;
    gap: 15%;

    .link {
      font-size: 28px;
      color: #999183;

      &:not(.active) {
        transition: none;
      }

      &.active {
        color: #e8ce80;
        transition: color 1s;
      }
    }

    .image-wrapper {
      position: relative;

      img {
        width: 80%;
      }
    }

    .image-hover {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s ease-out, transform 0.3s ease-out;

      &:hover {
        opacity: 1;
        transform: scale(1.25);
      }
    }
  }

  svg {
    font-size: 28px;
    color: #999183;
    transition: 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
      color: #e8ce80;
    }
  }

  img {
    height: 60px;
    width: 60px;
    cursor: pointer;
  }
}
