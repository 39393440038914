.meditation-set-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: $desktop-height;
  overflow: hidden;

  label {
    color: black;
  }

  input {
    @include disableAutofill;
    background-color: transparent;
    border-color: black;
    box-shadow: 0px 3px 10px -5px black;
    color: black;
  }

  .meditation-form-container {
    form {
      .image-uploader,
      .delete-set {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .number-input-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    box-shadow: 0px 3px 10px -5px black;
    border: black 1px solid;
    border-radius: 5px;

    span {
      position: absolute;
      left: 15px;
      top: 4.5px;
    }

    input {
      margin-left: 10px;
      width: 93%;
      border: none;
      box-shadow: none;
      height: 33px;
    }

    #price {
      box-sizing: border-box;
      padding-left: 25px;
      min-width: 100%;
    }
  }

  .image-display {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 30px;
    border: dotted 2px black;
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;

    .inner-image-display {
      display: flex;
      justify-content: center;
      margin: 10px 0;

      .chosen-img {
        width: 35%;
        border-radius: 10px;
      }

      .close {
        height: 25px;
        width: 25px;
        text-align: center;
        position: absolute;
        right: 2%;
        top: 5%;
      }
    }
  }

  .button-wrapper {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
    gap: min(18px, max(0px, 1vw));

    .cancel-btn {
      background: transparent;
      border-color: black;
      color: black;
    }
  }

  .file-upload {
    display: none;
  }

  .whisp-background-container {
    .top-left,
    .top-right {
      top: 56px;
    }
  }
}
