.order-meditations-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .meditations-text {
    text-align: center;
  }

  .order-meditations-wrapper {
    width: 70%;
    height: 375px;
    padding: 0 10px;
    overflow-y: auto;

    .meditation-item {
      display: grid;
      grid-template-columns: 40% 54% 6%;
      height: 40px;
      background-color: #f8f5ed;
      font-size: 18px;
      margin: 6px 0;
      border-radius: 15px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      cursor: pointer;

      .name {
        align-self: center;
        text-wrap: nowrap;
        overflow-x: auto;
        margin-left: 20px;
      }

      .title {
        align-self: center;
        font-style: italic;
        font-family: Arapey;
        font-weight: 300;
        text-wrap: nowrap;
        overflow-x: auto;
        margin: 0 5px;
      }

      .drag-icon {
        justify-self: center;
        align-self: center;
        font-size: 20px;
      }
    }

    .selected,
    .meditation-item:hover {
      color: $gold;
      background-color: #171717;
    }
  }

  .buttons-wrapper {
    display: flex;
    padding: 15px 0;

    button {
      height: 40px;
      margin: 10px;
      max-width: 40%;
    }

    .cancel-btn {
      background-color: white;
      color: black;
      border-color: black;
    }
  }
}
