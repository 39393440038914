.dropdown-menu-wrapper {
  @include flexbox(column);
  position: absolute;
  background-color: white;
  border-radius: 7px;
  width: 12rem;
  right: 5px;
  top: 45px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: 0.3s ease-in-out;

  .nav-item {
    padding: 10px 0 10px 0;
  }

  .line-break {
    border: 0.5px solid #594e3c33;
    width: 100%;
  }

  .link {
    padding: 8px 0;
    transition: ease-in 0.2s;
    color: #171717;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 300;

    &:last-child {
      font-weight: 500;
    }

    &:hover {
      cursor: pointer;
      color: #fbe6b4;
    }
  }
}
