.drag-drop-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-size: 16px;

  .heading-wrapper {
    max-width: 420px;
    margin: 0 40px;
  }

  .fa-arrow-up-from-bracket {
    font-size: max(34px, min(52px, 6vw));
  }

  button {
    position: relative;
    z-index: 10;
    font-size: 16px;
    cursor: pointer;
    margin-top: max(10px, min(30px, 3vw));
  }

  input {
    position: absolute;
    border-radius: 15px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    opacity: 0;
  }
}
