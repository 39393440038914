@media (max-width: 430px) {
  .subscription-container .meditations .meditation-set .price {
    margin: 0px;
  }

  .meditation-set {
    width: 100%;

    .card {
      margin: 0px;
      width: 100%;
    }

    .price {
      margin: 0px;
      width: 100%;
    }
  }

  .card-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0px 15px;

    .card {
      margin: 0px;
      width: 100%;
    }
  }

  .about-container {
    width: 100%;

    .card-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0px 15px;

      .card-link {
        .card {
          margin: 0px;
          width: 93vw;
        }
      }
    }
  }

  .admin-card {
    width: 100%;
  }

  .editable {
    .admin-card {
      .card {
        width: 100%;
      }

      .price {
        width: 100%;
      }
    }
  }

  .meditation-set-view .card-wrapper .meditations {
    flex-direction: column;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 92vw;

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 60px;

        .name {
          display: flex;
          width: 95%;
          justify-content: space-between;
        }
      }

      .banner {
        width: 97.5%;
      }
    }
  }

  .subscription-container .meditations .card {
    width: 92vw;
  }
}
