.email-sent-container {
  @include flexbox(column);
  width: 100%;
  height: 100vh;
  background-color: #171717;

  .check-email-msg {
    color: $gold;
    margin-top: 8%;
  }

  .logo {
    height: 200px;
  }

  .whisp-background-container {
    img {
      width: 10em;
    }

    .content-wrapper {
      justify-self: center;
    }
  }
}
